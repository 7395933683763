import { createRouter, createWebHistory } from "@ionic/vue-router";
import Auth from "../Auth.js";

const routes = [
  {
    path: "/",
    redirect: "/login",
  },
  {
    path: "/home",
    name: "Home",
    component: () => import("@/views/HomePage.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/send_money",
    name: "SendMoney",
    component: () => import("@/views/send_money/IndexPage.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/airtime",
    name: "BuyAirtime",
    component: () => import("@/views/airtime/IndexPage.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/airtime_to_cash",
    name: "AirtimeToCash",
    component: () => import("@/views/airtime_to_cash/IndexPage.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/data",
    name: "BuyData",
    component: () => import("@/views/data/IndexPage.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/electricity",
    name: "BuyElectricity",
    component: () => import("@/views/electricity/IndexPage.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/tv_cable_payment",
    name: "BuyTvCable",
    component: () => import("@/views/tv_cable_payment/IndexPage.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/education",
    name: "BuyEducation",
    component: () => import("@/views/education/IndexPage.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/giftcard",
    name: "BuyGiftCard",
    component: () => import("@/views/giftcard/IndexPage.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/transactions",
    name: "UserTransactions",
    component: () => import("@/views/transactions/IndexPage.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/profile",
    name: "UserProfile",
    component: () => import("@/views/user_profile/IndexPage.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/help",
    name: "Help",
    component: () => import("@/views/help/IndexPage.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/quiz",
    name: "Quiz",
    component: () => import("@/views/quiz/IndexPage.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/auth/LoginPage.vue"),
  },
  {
    path: "/login2",
    name: "Login2",
    component: () => import("@/views/auth/LoginPage2.vue"),
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("@/views/auth/RegistrationPage.vue"),
  },
  {
    path: "/add_account_number",
    name: "AddAccountNumber",
    component: () => import("@/views/account_number/AddPage.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/fund_account",
    name: "FundAccount",
    component: () => import("@/views/fund_wallet/IndexPage.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/transaction_pin",
    name: "TransactionPin",
    component: () => import("@/views/transaction_pin/IndexPage.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/success",
    name: "SuccessPage",
    component: () => import("@/views/include/SuccessPage.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/welcome",
    name: "welComePage",
    component: () => import("@/views/splashscreen/IndexPage.vue"),
  
  },
  {
    path: "/trade_money",
    name: "TradeMoney",
    component: () => import("@/views/trade/currency/IndexPage.vue"),
    meta: {
      requiresAuth: true,
    },
    
  },
  {
    path: "/blog",
    name: "Blog",
    component: () => import("@/views/blog/IndexPage.vue"),
    meta: {
      requiresAuth: true,
    },
    
  }
  ,
  {
    path: "/blog/:slug",
    name: "SingleBlog",
    component: () => import("@/views/blog/ShowPage.vue"),
    meta: {
      requiresAuth: true,
    },
    
  }
  ,
  {
    path: "/live_table",
    name: "LiveTable",
    component: () => import("@/views/live_table/IndexPage.vue"),
    meta: {
      requiresAuth: true,
    },
    
  },
  {
    path: "/up_coming_games",
    name: "UpComingGames",
    component: () => import("@/views/quiz/UpComingGames.vue"),
    meta: {
      requiresAuth: true,
    },
    
  }
  ,
  {
    path: "/game/:id",
    name: "SingleGame",
    component: () => import("@/views/quiz/SingleGame.vue"),
    meta: {
      requiresAuth: true,
    },
    
  }
,
  {
    path: "/game/play/:id",
    name: "SingleGamePlay",
    component: () => import("@/views/quiz/PayForGame.vue"),
    meta: {
      requiresAuth: true,
    },
    
  }
  ,
  {
    path: "/game/play/question/:id",
    name: "SingleGamePlayQuestion",
    component: () => import("@/views/quiz/QuizQuestion.vue"),
    meta: {
      requiresAuth: true,
    },
    
  }
  ,
  {
    path: "/ads",
    name: "AdsIndexPage",
    component: () => import("@/views/ads/IndexPage.vue"),
    
    
  }
  ,
  {
    path: "/forgot-password",
    name: "ForgetPassword",
    component: () => import("@/views/auth/ForgetPasswordPage.vue"),
    
    
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth) ) {
      if (Auth.check()) {
          next();
          return;
      } else {
          router.push('/');
      }
  } else {
    console.log(routes.path)
      next();
  }
});
export default router;
