<template>
  <ion-page>
    <ion-content>
      <div>
        <div class="grid md:grid-cols-6 gap-0 min-h-screen bg-gray-100">
          <SideBarMenu />

          <div class="p-2 md:px-10 col-span-5">
            <div
              class="rounded overflow-hidden shadow-lg text-gray-900 bg-white p-3 mt-10"
            >
              <div class="grid grid-rows-3 grid-flow-col gap-4 text-blue-700">
                <div class="row-span-3 text- font-bold text-gray-900 p-5 pl-0">
                  <span class="text-sm"
                    ><ion-icon
                      name="arrow-back-outline"
                      class="text-2xl"
                      @click="ShowAccountDetails()"
                    ></ion-icon
                  ></span>
                </div>
                <div class="row-span-3 p-5">
                  <p class="text-xl font-bold text-gray-900">Account Details</p>
                  <!-- ENGREG<img src="assets/logo.png" class="w-8 mx-auto"> -->
                  <!-- ENGREG  crcftg -->
                </div>
                <div class="row-span-3 p-5 text-right">
                  <!-- <ion-icon name="menu-outline" class="text-gray-100 text-2xl "  @click="showMenu = !showMenu" ></ion-icon> -->
                </div>
              </div>

              <div>
                <div class="max-w-3xl mx-auto px-4 py-5">
                  <div>
                    <!-- /Top Navigation -->

                    <!-- Step Content -->
                    <div class="">
                      <div>
                        <!-- <div class="mb-5 text-center">
                          <div
                            class="mx-auto w-32 h-32 mb-2 border rounded-full relative bg-gray-100 mb-4 shadow-inset"
                          >
                            <img
                              id="image"
                              class="object-cover w-full h-32 rounded-full"
                              :src="image"
                            />
                          </div>

                          <label
                            for="fileInput"
                            type="button"
                            class="cursor-pointer inine-flex justify-between items-center focus:outline-none border py-2 px-4 rounded-lg shadow-sm text-left text-gray-600 bg-white hover:bg-gray-100 font-medium"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              class="inline-flex flex-shrink-0 w-6 h-6 -mt-1 mr-1"
                              viewBox="0 0 24 24"
                              stroke-width="2"
                              stroke="currentColor"
                              fill="none"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            >
                              <rect
                                x="0"
                                y="0"
                                width="24"
                                height="24"
                                stroke="none"
                              ></rect>
                              <path
                                d="M5 7h1a2 2 0 0 0 2 -2a1 1 0 0 1 1 -1h6a1 1 0 0 1 1 1a2 2 0 0 0 2 2h1a2 2 0 0 1 2 2v9a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-9a2 2 0 0 1 2 -2"
                              />
                              <circle cx="12" cy="13" r="3" />
                            </svg>
                            Browse Photo
                          </label>

                          <div
                            class="mx-auto w-48 text-gray-500 text-xs text-center mt-1"
                          >
                            Click to add profile picture
                          </div>

                          <input
                            name="photo"
                            id="fileInput"
                            accept="image/*"
                            class="hidden"
                            type="file"
                            @change="
                              let file =
                                document.getElementById('fileInput').files[0];
                              var reader = new FileReader();
                              reader.onload = (e) => (image = e.target.result);
                              reader.readAsDataURL(file);
                            "
                          />
                        </div> -->

                        <div class="mb-5">
                          <label
                            for="firstname"
                            class="font-bold mb-1 text-gray-700 block"
                            >Account Number</label
                          >
                          <input
                            type="text"  :value="user.account_number"
                            class="mt-5 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                            placeholder="Your  account number will apear here" 
                          />
                        </div>

                        <div class="mb-5">
                          <label
                            for="firstname"
                            class="font-bold mb-1 text-gray-700 block"
                            >Bank</label
                          >
                          <input
                            type="text"  :value="user.bank"
                            class="mt-5 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                            placeholder="Your bank name will apear here"
                          />
                        </div>
                        <div class="mb-5">
                          <label
                            for="firstname"
                            class="font-bold mb-1 text-gray-700 block"
                            >Username</label
                          >
                          <input
                            type="text" :value="user.username"
                            class="mt-5 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                            placeholder="Your username will appear here"
                          />
                        </div>
                        <div class="mb-5">
                          <label
                            for="firstname"
                            class="font-bold mb-1 text-gray-700 block"
                            >First Name</label
                          >
                          <input
                            type="text" :value="user.first_name"
                            class="mt-5 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                            placeholder="Your first name will appear here"
                          />
                        </div>

                        <div class="mb-5">
                          <label
                            for="firstname"
                            class="font-bold mb-1 text-gray-700 block"
                            >Last Name</label
                          >
                          <input
                            type="text" :value="user.last_name"
                            class="mt-5 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                            placeholder="Your lastname will appear here"
                          />
                        </div>

                        <div class="mb-5">
                          <label
                            for="firstname"
                            class="font-bold mb-1 text-gray-700 block"
                            >Other Names</label
                          >
                          <input
                            type="text" :value="user.other_name"
                            class="mt-5 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                            placeholder="Your other names will appear here"
                          />
                        </div>

                        <div class="mb-5">
                          <label
                            for="firstname"
                            class="font-bold mb-1 text-gray-700 block"
                            >Phone Number</label
                          >
                          <input
                            type="text" :value="user.phone"
                            class="mt-5 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                            placeholder="Your phone number will appear here"
                          />
                        </div>


                        <div class="mb-5">
                          <label
                            for="email"
                            class="font-bold mb-1 text-gray-700 block"
                            >Email</label
                          >
                          <input
                            type="email" :value="user.email"
                            class="mt-5 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                            placeholder="Your email will appear here"
                          />
                        </div>


                        <p class="text-red-500 text-bold">
                            To update your details contact us using any of our communication channel
                        </p> <br>
                        <button @click="logout()"
                  class="bg-red-600 rounded-xl text-white py-3 px-14 hover:scale-105 duration-300 w-100 w-full"
                >
                 Request Delete of account
                      </button>
                      </div>
                    </div>
                    <!-- / Step Content -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br /><br /> <br /><br /> <br /><br />
        <LoadingIndicator  v-bind:class="{
              hidden: loading == false,
            }" />
        <!-- fixed nav -->
        <BottomNavMenu />
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonPage, IonContent } from "@ionic/vue";
import { defineComponent } from "vue";
import { useRouter } from "vue-router";
import Auth from "../../Auth.js";

export default defineComponent({
  name: "HomePage",
  components: {
    IonPage,
    IonContent,
  },
  setup() {
    const router = useRouter();
    return { router };
  },
  data: () => ({
    active: 0,
    loggedUser: Auth.user,
    transaction_pin_confirmation: "",
    username: Auth.user.username,
    confirmPayment: false,
    transaction_pin: "",
    btnText: "SUBMIT",
    errors: [],
    account_details: "",
    user: [],
    loading: false,
  }),
  mounted() {
    //this.$swal('Hello Vue world!!!');
    this.getProfile()
  },
  methods: {
    ShowAccountDetails() {
      window.location.href = "/profile";
    
    },
    editPayment() {
      this.confirmPayment = false;
    },
    getProfile() {
      this.disableButton = true;
      this.loading = true;
      this.btnText = "Please wait...";
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + Auth.token,
        },
      };
      fetch(this.base_api_url + "user/profile", requestOptions)
        .then(async (response) => {
          const data = await response.json();
          this.disableButton = false;
          this.loading = false;
          // check for error response
          if (!response.ok) {
           
            // console.log(data.errors);

            if (data.errors) {
              this.errors = data.errors;
            }

            // get error message from body or default to response status
            const error = (data && data.message) || response.status;
            return Promise.reject(error);
          }

          this.loginFailed = false;
          //console.log(data.data)
         
         this.user = data.data
          //window.location.href = "/home";
          //  this.$router.push("/home");
        })
        .catch((error) => {
          //alert(process.env.VUE_APP_API_BASE_URL);
          this.btnText = "Login";
          this.loginFailed = true;
          this.$swal.fire({
            title: "Sorry!",
            text: error,
            icon: "error",
            heightAuto: false,
          });
          this.errorMessage = error;
          // this.errors =error;
          console.log(error);
        });
    },
    logout() {
      // POST request using fetch with error handling
      // alert(this.base_file_url)
      this.loading = true;
      this.btnText = "Please wait...";
      this.disableButton = true;
      this.LogoutText = "Please wait...";
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + Auth.token,
        },
        body: JSON.stringify({
          email: "",
        }),
      };
      fetch(this.base_api_url + "logout", requestOptions)
        .then(async (response) => {
          const data = await response.json();

          this.loading = false;
          this.LogoutText = "Logout";
          // check for error response
          if (!response.ok) {
            this.LogoutText = "Logout";
            console.log(data.errors);
            this.errors = data.errors;
            // get error message from body or default to response status
            const error = (data && data.message) || response.status;
            return Promise.reject(error);
          }

          this.loginFailed = false;

          Auth.logout(data.data.access_token, data.data);
          // this.$swal.fire({
          //   title: "Success",
          //   text: "Logout was successful",
          //   icon: "success",
          //   heightAuto: false,
          // });
         // this.$router.push("/login?ref=delete&s="+this.user.support_pin);
          window.location.href ="/login?ref=delete&s="+this.user.support_pin
         // window.location.href = "https://savvykash.com/request-delete-account?ref="+this.user.support_pin;
          // this.$router.push("/");
        })
        .catch((error) => {
          //alert(process.env.VUE_APP_API_BASE_URL);
          this.LogoutText = "Logout";
          this.loginFailed = true;
          this.$swal.fire({
            title: "Success",
            text: "Logout was successful",
            icon: "success",
            heightAuto: false,
          });
          Auth.logout('user', 'token');
          window.location.href = "/";
          this.errorMessage = error;
          console.log(error);
        });
    }
  },
});
</script>
