<template>
    <div

class="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-700 opacity-75 flex flex-col items-center justify-center"
>
<div class="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div>
<h2 class="text-center text-white text-xl font-semibold">
  Please wait...
</h2>
<!-- <p class="w-1/3 text-center text-white">This may take a few seconds, please don't close this page.</p> -->
</div>
</template>
<script >
import { defineComponent } from "vue";
import { useRouter } from "vue-router";

export default defineComponent({
 name: "HomePage",
  props: ['loading'],
  
 components: {

 },
 data: () => ({
   showMenu : false,
 }),
 setup() {
   const router = useRouter();
    
   return { router };
 },
 mounted() {
 // console.log(this.name)
//alert(this.page_title)
 },
 
 
});

</script>
