<template>
    <div>
        <div class="grid grid-rows-3 grid-flow-col gap-4 text-blue-700 ">
         <div class="row-span-3 text- font-bold text-gray-900 p-5 pl-0">
           <span class="text-sm"><ion-icon name="arrow-back-outline" class="text-2xl" @click="$router.go(-1)"></ion-icon></span> 
         </div>
         <div class="row-span-3 p-5">
           <p class="text-xl font-bold text-gray-900">
              {{page_title}} 
           </p>
           <!-- ENGREG<img src="assets/logo.png" class="w-8 mx-auto"> -->
           <!-- ENGREG  crcftg -->
         </div>
         <div class="row-span-3 p-5 text-right">
           <!-- <ion-icon name="menu-outline" class="text-gray-100 text-2xl "  @click="showMenu = !showMenu" ></ion-icon> -->
         </div>
    </div>

        <!-- <div
      class="shadow-lg rounded-lg p-5 bg-gradient-to-r from-gray-900 to-gray-500 mt-3 flex justify-between"
    >
      <div class="">
        <a href="#">
          <div class="  ">
            <p class="text-gray-300">Current Balance</p>
            <h2 class="text-2xl font-semibold mb-2 text-white mt-0">
              $4,456,34
            </h2>
          </div>
        </a>
      </div>
      <div class="d text-white">
        <ion-icon name="eye-off-outline" class="text-xl mt-5"></ion-icon>
      </div>
    </div> -->
   
    </div>
   

          <!-- Mobile menu -->
     

       
</template>

<script >
import { defineComponent } from "vue";
import { useRouter } from "vue-router";

export default defineComponent({
 name: "HomePage",
  props: ['page_title'],
  
 components: {

 },
 data: () => ({
   showMenu : false,
 }),
 setup() {
   const router = useRouter();
    
   return { router };
 },
 mounted() {
 // console.log(this.name)
//alert(this.page_title)
 },
 
 
});

</script>
