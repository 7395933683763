<template>
  <ion-page>
    <ion-content>
      <div>
        <div class="grid md:grid-cols-6 gap-0 min-h-screen bg-gray-100">
          <SideBarMenu />

          <div class="p-2 md:px-10 col-span-5">
            <div
              class="rounded overflow-hidden shadow-lg text-gray-900 bg-white p-3 mt-10"
              v-if="!confirmPayment"
            >
              <div class="grid grid-rows-3 grid-flow-col gap-4 text-blue-700">
                <div class="row-span-3 text- font-bold text-gray-900 p-5 pl-0">
                  <span class="text-sm"
                    ><ion-icon
                      name="arrow-back-outline"
                      class="text-2xl"
                      @click="goBack()"
                    ></ion-icon
                  ></span>
                </div>
                <div class="row-span-3 p-5">
                  <p class="text-sm font-bold text-gray-900">Change Password</p>
                  <!-- ENGREG<img src="assets/logo.png" class="w-8 mx-auto"> -->
                  <!-- ENGREG  crcftg -->
                </div>
                <div class="row-span-3 p-5 text-right">
                  <!-- <ion-icon name="menu-outline" class="text-gray-100 text-2xl "  @click="showMenu = !showMenu" ></ion-icon> -->
                </div>
              </div>

              <!-- <p class="text-red-500 mb-2 text-center font-bold ">
                  Select a Four (4) Digit Transaction Pin
                </p> -->
              <form @submit.prevent="changePassword">
                <input
                  type="password"
                  id="first_name"
                  min="0"
                  max="9999"
                  class="mt-5 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  placeholder="Current Password"
                  v-bind:class="{ 'border-red-700': errors.current_password }"
                  v-model="current_password"
                />
                <span
                  v-if="errors.current_password"
                  class="block text-sm text-red-700"
                  >{{ errors.current_password[0] }}</span
                >

                <input
                  type="password"
                  min="0"
                  max="9999"
                  id="first_name"
                  class="mt-5 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  placeholder="New Password"
                  v-bind:class="{
                    'border-red-700': errors.password,
                  }"
                  v-model="password"
                />
                <span
                  v-if="errors.password"
                  class="block text-sm text-red-700"
                  >{{ errors.password[0] }}</span
                >
                <input
                  type="password"
                  min="0"
                  max="9999"
                  id="first_name"
                  class="mt-5 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  placeholder="Re-Type Password"
                  v-bind:class="{
                    'border-red-700': errors.password_confirmation,
                  }"
                  v-model="password_confirmation"
                />
                <span
                  v-if="errors.password_confirmation"
                  class="block text-sm text-red-700"
                  >{{ errors.password_confirmation[0] }}</span
                >
                <button
                  class="rounded-lg px-4 py-4 bg-gradient-to-r from-gray-900 to-gray-500 text-gray-100 hover:bg-gray-700 duration-300 mt-8 w-full mb-5"
                  type="submit"
                >
                  {{ btnText }}
                </button>
              </form>
            </div>
          </div>
        </div>
        <br /><br />

        <!-- fixed nav -->
        <BottomNavMenu />
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonPage, IonContent } from "@ionic/vue";
import { defineComponent } from "vue";
import { useRouter } from "vue-router";
import Auth from "../../Auth.js";

export default defineComponent({
  name: "HomePage",
  components: {
    IonPage,
    IonContent,
  },
  setup() {
    const router = useRouter();
    return { router };
  },
  data: () => ({
    active: 0,
    loggedUser: Auth.user,
    confirmPayment: false,
    transaction_pin: "",
    password_confirmation: "",
    current_password: "",
    password: "",
    btnText: "Change Password",
    errors: [],
  }),
  mounted() {
    //this.$swal('Hello Vue world!!!');
  },
  methods: {
    goBack() {
      window.location.href = "/profile";
    },
    changePassword() {
      // POST request using fetch with error handling
      // alert(this.base_file_url)
      this.disableButton = true;
      this.btnText = "Please wait...";
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + Auth.token,
        },
        body: JSON.stringify({
          current_password: this.current_password,
          password: this.password,
          password_confirmation: this.password_confirmation,
          
        }),
      };
      fetch(this.base_api_url + "user/change/password", requestOptions)
        .then(async (response) => {
          const data = await response.json();
          this.disableButton = false;
          this.btnText = "Change Password";
          // check for error response
          if (!response.ok) {
            this.btnText = "Change Password";
            console.log(data.errors);
            this.errors = data.errors;
            // get error message from body or default to response status
            const error = (data && data.message) || response.status;
            return Promise.reject(error);
          }

          this.loginFailed = false;
          this.$swal.fire({
            title: "Success",
            text: 'Password change successfully',
            icon: "success",
            heightAuto: false,
          });
          this.$router.push("/home");
        })
        .catch((error) => {
          //alert(process.env.VUE_APP_API_BASE_URL);
          this.btnText = "Change Password";
          this.loginFailed = true;
          this.presentAlert(error);
          this.errorMessage = error;
          console.log(error);
        });
    },
  },
});
</script>
