import { createApp } from 'vue'
import App from './App.vue'
import router from './router';

import { IonicVue } from '@ionic/vue';
window.Vue = require('vue');
/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';

//custom include
 import BottomNavMenu from './views/include/BottomNavMenu.vue'
 import TopNavMenu from './views/include/TopNavMenu.vue'
 import SideBarMenu from './views/include/SideBarMenu.vue'
 import LoadingIndicator from './views/include/LoadingIndicator.vue'
 import AccountDetails from './views/user_profile/AccountDetail.vue'
 import TransactionPin from './views/user_profile/TransactionPin.vue'
 import ChangePassword from './views/user_profile/ChangePassword.vue'
 import MinorUpdateNotice from './views/include/MinorUpdateNotice.vue'
 import MajorUpdateNotice from './views/include/MajorUpdateNotice.vue'

 import SetTransactionPin from './views/transaction_pin/PopUP.vue'


 //auth
 //import store from './store';

 import VueSweetalert2 from 'vue-sweetalert2';
 import 'sweetalert2/dist/sweetalert2.min.css';
 import Flutterwave from "flutterwave-vue3";
 
 
 //auth
 import Auth from './Auth.js';

 //auto logout

 import logoutPlugin from 'vue-auto-logout'


 //ads
 

const app = createApp(App)
  .use(IonicVue)
  .use(Auth)
  .use(router)
  .use(VueSweetalert2).use(Flutterwave);
  
  //auto logout
  const logoutOptions = {
    stagnateTime: 5 * 60 * 1000, //3 minutes -> 3 * 60 * 1000
    detectTime: 1 * 60 * 1000,
    enable: true,
    keyName: 'token',
    clearAll:true,
  }
  
  app.use(logoutPlugin, logoutOptions)

  app.component('BottomNavMenu', BottomNavMenu);
  app.component('TopNavMenu', TopNavMenu);
  app.component('SideBarMenu', SideBarMenu);
  app.component('LoadingIndicator', LoadingIndicator);
  app.component('AccountDetails', AccountDetails);
  app.component('TransactionPin', TransactionPin);
  app.component('ChangePassword', ChangePassword);
  app.component('MinorUpdateNotice', MinorUpdateNotice);
  app.component('MajorUpdateNotice', MajorUpdateNotice);
  app.component('SetTransactionPin', SetTransactionPin);
 app.config.globalProperties.base_api_url = 'https://api.savvykash.com/api/v1/';
 app.config.globalProperties.base_url = 'https://api.savvykash.com';
 app.config.globalProperties.portal_url = 'https://portal.savvykash.com'
//app.config.globalProperties.base_api_url = 'http://localhost:8000/api/v1/';
//app.config.globalProperties.base_url = 'http://localhost:8000';
 

router.isReady().then(() => {
  app.mount('#app');
});