<template>
    <div
      class="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-white o flex flex-col items-center justify-center"
    id="minorUpdate">
      <img src="/assets/logo/savvy cashdav.png" alt="" class="h-16" />
     
      <p class="w-full text-center text-gray-900 font-bold px-2 mt-5" v-if="verion_data_data">
        New update version  ({{  verion_data_data.version}}) available kindly update your application for better experience

       
      </p>
  
      <h2 class="text-center text-white text-xl font-semibold" v-if="verion_data_data">
        <a :href=" verion_data_data.update_url_google">
          <img src="/assets/logo/playstore.png" alt="" class="h-16" />
        </a>
      </h2>
      <h2 class="text-center text-white text-xl font-semibold" v-if="verion_data_data">
        <a :href=" verion_data_data.update_url_apple">
          <img src="/assets/logo/applestore.png" alt="" class="h-10" />
        </a>
      </h2>

      <button @click="HideUpdate()"
                  class="bg-[#001446] rounded text-white py-3 hover:scale-105 duration-300 px-3 mt-5"
                >
                Remind me Later
                </button>
     
    </div>
  </template>
  <script>
  import { defineComponent } from "vue";
  import { useRouter } from "vue-router";
  
  export default defineComponent({
    name: "HomePage",
    props: ["verion_data_data"],
    
  
    components: {},
    data: () => ({
      showMenu: false,
    }),
    setup() {
      const router = useRouter();
  
      return { router };
    },
    mounted() {
      // console.log(this.name)
      //alert(this.page_title)
    },
    methods: {
        HideUpdate() {
            var element = document.getElementById("minorUpdate");
  element.classList.add("hidden");
        }

    }
  });
  </script>
  