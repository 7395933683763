<template>
    <div>
        <section
        id="bottom-navigation"
        class="block fixed inset-x-0 bottom-0 z-10 bg-white shadow-lg"
      >
        <div id="tabs" class="flex justify-between mb-3 mt-3 text-gray-900">
          <a
          @click="() => router.push('/home')"
            class="w-full focus:text-teal-500 hover:text-teal-500 justify-center inline-block text-center pt-2 pb-1 text-gray-900"
          >
            <svg
              width="25"
              height="25"
              viewBox="0 0 42 42"
              class="inline-block mb-1"
            >
              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <path
                  d="M21.0847458,3.38674884 C17.8305085,7.08474576 17.8305085,10.7827427 21.0847458,14.4807396 C24.3389831,18.1787365 24.3389831,22.5701079 21.0847458,27.6548536 L21.0847458,42 L8.06779661,41.3066256 L6,38.5331279 L6,26.2681048 L6,17.2542373 L8.88135593,12.4006163 L21.0847458,2 L21.0847458,3.38674884 Z"
                  fill="currentColor"
                  fill-opacity="0.1"
                ></path>
                <path
                  d="M11,8 L33,8 L11,8 Z M39,17 L39,36 C39,39.3137085 36.3137085,42 33,42 L11,42 C7.6862915,42 5,39.3137085 5,36 L5,17 L7,17 L7,36 C7,38.209139 8.790861,40 11,40 L33,40 C35.209139,40 37,38.209139 37,36 L37,17 L39,17 Z"
                  fill="currentColor"
                ></path>
                <path
                  d="M22,27 C25.3137085,27 28,29.6862915 28,33 L28,41 L16,41 L16,33 C16,29.6862915 18.6862915,27 22,27 Z"
                  stroke="currentColor"
                  stroke-width="2"
                  fill="currentColor"
                  fill-opacity="0.1"
                ></path>
                <rect
                  fill="currentColor"
                  transform="translate(32.000000, 11.313708) scale(-1, 1) rotate(-45.000000) translate(-32.000000, -11.313708) "
                  x="17"
                  y="10.3137085"
                  width="30"
                  height="2"
                  rx="1"
                ></rect>
                <rect
                  fill="currentColor"
                  transform="translate(12.000000, 11.313708) rotate(-45.000000) translate(-12.000000, -11.313708) "
                  x="-3"
                  y="10.3137085"
                  width="30"
                  height="2"
                  rx="1"
                ></rect>
              </g>
            </svg>
            <span class="tab tab-home block text-xs">Home</span>
          </a>
          <a
          @click="() => router.push('/transactions')"
            class="w-full focus:text-teal-500 hover:text-teal-500 justify-center inline-block text-center pt-2 pb-1 text-gray-900"
          >
          <svg class="inline-block mb-1" style="color: rgb(0, 0, 0);" width="25" stroke-width="1.5" height="25" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"> 
            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <path d="M9 6L20 6" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" fill="#000000"></path> <path d="M3.80002 5.79999L4.60002 6.59998L6.60001 4.59999" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" fill="#000000"></path> <path d="M3.80002 11.8L4.60002 12.6L6.60001 10.6" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" fill="#000000"></path> <path d="M3.80002 17.8L4.60002 18.6L6.60001 16.6" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" fill="#000000"></path> <path d="M9 12L20 12" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" fill="#000000"></path> <path d="M9 18L20 18" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" fill="#000000"></path>
          </g>
          </svg>
          
            <span class="tab tab-kategori block text-xs">Transactions</span>
          </a>
          <a
          @click="() => router.push('/profile')"
            class="w-full focus:text-teal-500 hover:text-teal-500 justify-center inline-block text-center pt-2 pb-1 text-gray-900"
          >
          <svg  xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" class="feather feather-settings inline-block mb-1">
            <circle cx="12" cy="12" r="3"></circle><path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path></svg>
            <span class="tab tab-explore block text-xs">Settings</span>
          </a>
          <a
          @click="() => router.push('/help')"
            class="w-full focus:text-teal-500 hover:text-teal-500 justify-center inline-block text-center pt-2 pb-1 text-gray-900"
          >
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" class="feather feather-help-circle inline-block mb-1">
            <circle cx="12" cy="12" r="10"></circle><path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path><line x1="12" y1="17" x2="12.01" y2="17"></line></svg>
            <span class="tab tab-whishlist block text-xs">Help</span>
          </a>
          <a
          @click="() => router.push('/profile')"
            class="w-full focus:text-teal-500 hover:text-teal-500 justify-center inline-block text-center pt-2 pb-1 text-gray-900"
          >
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user inline-block mb-1"><path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="8.5" cy="7" r="4"></circle><line x1="20" y1="8" x2="20" y2="14"></line><line x1="23" y1="11" x2="17" y2="11"></line></svg>         <span class="tab tab-account block text-xs">Account</span>
          </a>
        </div>

       
      </section>  
 <SetTransactionPin  v-if="!this.transaction_pin" />
      <div v-if="!this.verified_at"
    class="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-white o flex flex-col items-center justify-center"
  >
  <svg class="mb-4 h-20 w-20 text-green-500 mx-auto" viewBox="0 0 20 20" fill="currentColor">  <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"/></svg>
    
    <h2 class="text-2xl mb-4 text-gray-800 text-center font-bold">Registration Was Successful</h2>
   
    <div class="w-full text-center text-gray-900 font-bold px-2 mt-5">
       Please check your mail and enter the verification code to proceed

       
       <div class="p-4 mt-5">
        <form @submit.prevent="verify" >
        <input
                    class="p-3 rounded-xl border w-full bg-white text-gray-900"
                    type="text"
                    name="password"
                    placeholder="Verification Code"
                    v-bind:class="{ 'border-red-700': errors.code }"
                  v-model="code"
                    id="password"
                  />
                  <span v-if="errors.code" class="block text-sm text-red-700">{{
                  errors.code[0]
                }}</span>
                  <button
                  class="bg-[#001446] rounded-xl text-white py-3 hover:scale-105 duration-300 mt-5 w-full"
                >
                  {{ btnText }}
                </button>
                </form>
       </div>

       
      </div>
     
      <div class="w-full text-center text-gray-900  px-2 mt-5" @click="resendVerification()" v-if="!timer">
      Did not get the code ? <p class="font-bold">Click here</p> to request another 
     
      </div>
      <div class="w-full text-center text-gray-900  px-2 mt-5" @click="resendVerification()" v-if="timer">
     Request another in
      {{ timer }} seconds
      </div>
      <LoadingIndicator  v-bind:class="{
              hidden: loading == false,
            }" />
             

   
  </div>
    </div>
</template>


<script>
import { defineComponent } from "vue";
import { useRouter } from "vue-router";
import Auth from "../../Auth.js";

export default defineComponent({
  name: "HomePage",
  components: {
    
  },
  setup() {
    const router = useRouter();
    return { router };
  },
  data: () => ({
    errors: [],
    disableButton: false,
    loginFailed: false,
    btnText: "Verify",
    code: "",
    loading: false,
    verified_at: Auth.user.email_verified_at,
    transaction_pin: Auth.user.transactions_pin,
    timer:''
  }),
  created() {
  console.log(Auth.user.email_verified_at)
  },
  methods: {
    verify() {
      this.disableButton = true;
      this.btnText = "Please wait...";
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + Auth.token,
        },
        body: JSON.stringify({
          code: this.code,
        }),
      };
      fetch(
        this.base_api_url + "verify/email",
        requestOptions
      )
        .then(async (response) => {
          const data = await response.json();
          this.disableButton = false;
          this.btnText = "Verify";
          // check for error response
          if (!response.ok) {
            this.btnText = "Verify";
           // console.log(data.errors);

           //delete token if token expire
           if(response.status === 401) {
            window.localStorage.clear();
           }
           
           if( data.errors ){
              this.errors = data.errors;
             }
           
           
            // get error message from body or default to response status
            const error = (data && data.message) || response.status;
            return Promise.reject(error);
          }

           this.loginFailed=false
           //console.log(data.data)
          // console.log(data.data.access_token)
          Auth.login(data.data.access_token,data.data); 
          this.$swal.fire({
            title: "Success",
            text: 'Verification successful',
            icon: "success",
            heightAuto: false,
          });
          window.location="/home"
         this.$router.push('/home');
        })
        .catch((error) => {
          //alert(process.env.VUE_APP_API_BASE_URL);

           
            this.btnText = "Login";
          this.loginFailed=true
          this.$swal.fire({
            title: "Sorry!",
            text: error,
            icon: "error",
            heightAuto: false,
          });
          this.errorMessage = error;
         // this.errors =error;
          console.log(error);
          //delete token if token expire
          if(error == 'Unauthenticated') {
            window.localStorage.clear();
            window.location="/login"
          
           }
        });
    },
    resendVerification() {
     
      this.loading =true;
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + Auth.token,
        },
        body: JSON.stringify({
         
        }),
      };
      fetch(
        this.base_api_url + "verify/email/resend",
        requestOptions
      )
        .then(async (response) => {
          const data = await response.json();
        
          this.loading =false;
          // check for error response
          if (!response.ok) {
           
            if(response.status === 401) {
              window.localStorage.clear();
           }
           // console.log(data.errors);
          
           if( data.errors ){
              this.errors = data.errors;
             }
           
           
            // get error message from body or default to response status
            const error = (data && data.message) || response.status;
            return Promise.reject(error);
          }

         
       
          this.$swal.fire({
            title: "Success",
            text: 'A new verification code have been sent to your mail address, Please enter the code from your mail to proceed',
            icon: "success",
            heightAuto: false,
          });
          this.countDown(60)
         // window.location=""
         this.$router.push('/home');
        })
        .catch((error) => {
          //alert(process.env.VUE_APP_API_BASE_URL);
          this.loading =false;
          this.loginFailed=true
          this.$swal.fire({
            title: "Sorry!",
            text: error,
            icon: "error",
            heightAuto: false,
          });
          this.errorMessage = error;
         // this.errors =error;
          console.log(error);
          if(error == 'Unauthenticated') {
            window.localStorage.clear();
            window.location="/login"
           
           }
        });
    },
    countDown(sec) {
      this.timer = sec;
      
      this.interval = setInterval(() => {
        if (this.timer >0) {
        this.timer--;
        }
        if (this.timer === 0) {
          clearInterval(this.interval);
          return false;
        }
      }, 1000);
    },
  },
  watch: {
    timeout: {
      handler() {
        this.countDown(this.timeout);
      },
      immediate: true,
    },
  },
});
</script>

