import axios from 'axios';

class Auth {
    constructor () {
        
        this.token = window.localStorage.getItem('token');
        let userData = window.localStorage.getItem('user');
      
        this.user = userData ? JSON.parse(userData) : null;

        if (this.token) {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.token;
        }
    }
    login (token, user) {
        window.localStorage.setItem('token', token);
        window.localStorage.setItem('user', JSON.stringify(user));
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;

        this.token = token;
        this.user = user;
    }
    check () {
        return !! this.token;
    }
    checkIfBvnVerified () {
      return !! this.user.bnv_verified_at 
          
    }
    checkIfTransactionPin () {
        return !! this.user.transactions_pin 
            
      }
      checkIfEmailVerified () {
        return !! this.user.email_verified_at 
            
      }
      checkIfPhoneVerified () {
        return !! this.user.phone_verified_at 
            
      }
    logout () {
        // window.localStorage.clear();
        window.localStorage.removeItem('token');
        window.localStorage.removeItem('user');
        this.user = null;
    }
}
export default new Auth();