<template>
  <ion-app>
    <ion-router-outlet />
  </ion-app>
</template>

<script >
  import { IonApp, IonRouterOutlet } from '@ionic/vue';
  import { defineComponent } from 'vue';
  
  //console.log(Auth.user)
  export default defineComponent({
    name: 'App',
    components: {
      IonApp,
      IonRouterOutlet,
    },
    data() {
      return {
           
      }    
       },
        
       
  });
</script>